import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SpinnerService } from '../../services/spinner.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.sass'],
    standalone: false
})
export class SpinnerComponent implements OnInit {

  mode: ProgressSpinnerMode = 'determinate';

  value: number = 0;

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.loaderValue.subscribe((value: number) => {
      this.value = value;
    })
  }
}
